import React, { Suspense } from 'react'
import { HashRouter, Switch, Route } from 'react-router-dom'

const HomeView = React.lazy(() => import('./views/HomeView'))
const DocsView = React.lazy(() => import('./views/DocsView'))
const MapWidgetType1 = React.lazy(() => import('./views/widgets/MapType1/MapType1'))
const DuoMapWidgetType1 = React.lazy(() => import('./views/widgets/DuoMapType1/DuoMapType1'))
const AndroidAppView = React.lazy(() => import('./views/AndroidAppView'))
const TwitchChatView = React.lazy(() => import('./views/TwitchChatView'))

const Loader = () => <>LOADING APP...</>

const App = () => {

  return (
    <HashRouter>
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route exact path={'/'} render={props => <HomeView {...props} />} />
          <Route exact path={'/docs'} render={props => <DocsView {...props} />} />
          <Route exact path={'/map1'} render={props => <MapWidgetType1 {...props} />} />
          <Route exact path={'/duomap1'} render={props => <DuoMapWidgetType1 {...props} />} />
          <Route exact path={'/android-app'} render={props => <AndroidAppView {...props} />} />
          <Route exact path={'/twitch-chat'} render={props => <TwitchChatView {...props} />} />
        </Switch>
      </Suspense>
    </HashRouter>
  )
}

export default App
